.main-navbar.bg-white.sticky-top {
  background-color: #74daf7 !important;
}

.nav-link-icon.toggle-sidebar {
  color: white;
}

.logo-image {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 20%;
  border-radius: 5px;
}

body {
  background-color: #74daf791 !important;
}

.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item:hover {
  box-shadow: inset 0.1875rem 0 0 #74daf791 !important;
  background-color: #fbfbfb;
  color: #74daf7 !important;
}

.main-sidebar .nav .nav-item .nav-link.active i,
.main-sidebar .nav .nav-item .nav-link:hover i,
.main-sidebar .nav .nav-item.active i,
.main-sidebar .nav .nav-item:hover i {
  color: #74daf7 !important;
}

.modal {
  overflow-x: auto;
  overflow-y: auto;
  z-index: 9990 !important;
}
