.login{
  margin: auto;
}

.centered {
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  z-index: 100;
}

.page-title-center{
  margin: auto;
  min-width: 12%;
}
